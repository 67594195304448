import React from "react";
import PropTypes from "prop-types";
import "./book.scss";

const Book = ({
  book: {
    Cover_Image_URL,
    bookUrl,
    Title,
    Series,
    Author_Name,
    Publisher,
    Publication_Date,
    Genres,
  },
}) => {
  return (
    <div className="book-wrapper">
      <a
        href={bookUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="book-cover-image-url"
      >
        <img src={Cover_Image_URL} alt="book cover"></img>
      </a>
      <div className="book-author-name">{Author_Name}</div>
      <div className="book-title-wrapper">
        <div className="book-title">{Title}</div>
        <div className="book-series">{Series}</div>
      </div>
      <div className="book-publisher">{Publisher}</div>
      <div className="book-publication-date">{Publication_Date}</div>
      <div className="book-genres">{Genres}</div>
    </div>
  );
};

Book.propTypes = {
  book: PropTypes.shape({
    bookImage: PropTypes.string,
    bookUrl: PropTypes.string,
    Title: PropTypes.string,
    Series: PropTypes.string,
    Author_Name: PropTypes.string,
    Publisher: PropTypes.string,
    Publication_Date: PropTypes.string,
    Genres: PropTypes.string,
  }),
};

export default Book;
