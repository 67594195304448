import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import { Link } from "gatsby"

import "./member-book-gallery.scss"

import CappedWidth from "../components/capped-width/capped-width"

import Book from "../components/book/book"

function Publication_Date_As_Number({ Publication_Date }) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const [monthname, year] = Publication_Date.split(" ")
  const month = months.indexOf(monthname)
  const yearNum = parseInt(year)
  // just assume/use the first day of the month
  const day = 1
  const date = new Date(yearNum, month, day)
  // use the unix timestamp as the basis for calculating
  // which date is more recent than another date
  return date.getTime()
}

const MemberBookGalleryPage = () => {
  // TODO: remove the "Selected_Work" filter
  const {
    allBooksCsv: { nodes: books },
  } = useStaticQuery(graphql`
    {
      allBooksCsv(filter: { Selected_Work: { eq: "Yes" } }) {
        nodes {
          id
          Title
          Cover_Image_URL
          Author_Name
          Series
          Publication_Date
          Publisher
          Genres
        }
      }
    }
  `)

  const [selectedSort, setSelectedSort] = useState("title-a-z")
  const [showSortPicker, setShowSortPicker] = useState(false)

  const setSortBy = sortBy => () => {
    setSelectedSort(sortBy)
    setShowSortPicker(false)
  }

  // perform the actual sorting, according to the selected sorting method
  let sortedBooks
  switch (selectedSort) {
    case "publication-date-newest":
      sortedBooks = books.sort((a, b) =>
        Publication_Date_As_Number(b) > Publication_Date_As_Number(a) ? -1 : 1
      )
      break
    case "publication-date-oldest":
      sortedBooks = books.sort((a, b) =>
        Publication_Date_As_Number(b) > Publication_Date_As_Number(a) ? 1 : -1
      )
      break
    case "publisher-a-z":
      // sort alphabetically ascending
      sortedBooks = books.sort((a, b) => {
        return a.Publisher.toLowerCase() > b.Publisher.toLowerCase() ? 1 : -1
      })
      break
    case "publisher-z-a":
      // sort alphabetically descending
      sortedBooks = books.sort((a, b) => {
        return a.Publisher.toLowerCase() > b.Publisher.toLowerCase() ? -1 : 1
      })
      break
    case "author-name-a-z":
      // sort alphabetically ascending
      sortedBooks = books.sort((a, b) => {
        return a.Author_Name.toLowerCase() > b.Author_Name.toLowerCase()
          ? 1
          : -1
      })
      break
    case "author-name-z-a":
      // sort alphabetically descending
      sortedBooks = books.sort((a, b) => {
        return a.Author_Name.toLowerCase() > b.Author_Name.toLowerCase()
          ? -1
          : 1
      })
      break
    case "title-a-z":
      // sort alphabetically ascending
      sortedBooks = books.sort((a, b) => {
        return a.Title.toLowerCase() > b.Title.toLowerCase() ? 1 : -1
      })
      break
    case "title-z-a":
      // sort alphabetically descending
      sortedBooks = books.sort((a, b) => {
        return a.Title.toLowerCase() > b.Title.toLowerCase() ? -1 : 1
      })
      break
    default:
      sortedBooks = books
  }

  return (
    <Layout>
      <SEO title="Member Book Gallery" />

      <div className="section member-book-gallery">
        <CappedWidth>
          <h1 className="page-title">Member Book Gallery</h1>
          <p>
            Our members have made over 1,500 sales to print and online
            publications, won many awards (including the Hugo), and appeared in
            Best Of anthologies. Dozens of members have sold novels to major
            publishers.
          </p>
        </CappedWidth>
      </div>
      <div className="books-grid-header">
        <div className="books-grid-number">
          Showing {sortedBooks.length} books
        </div>
        <div className="books-grid-sorting">
          <div className="sort-by">Sort By</div>
          <div
            className="books-sorting-selected"
            onClick={() => setShowSortPicker(!showSortPicker)}
            onOutsideClick={() => setShowSortPicker(false)}
          >
            {selectedSort === "publication-date-newest" &&
              "PUBLICATION DATE NEWEST"}
            {selectedSort === "publication-date-oldest" &&
              "PUBLICATION DATE OLDEST"}
            {selectedSort === "publisher-a-z" && "PUBLISHER A-Z"}
            {selectedSort === "publisher-z-a" && "PUBLISHER Z-A"}
            {selectedSort === "author-name-a-z" && "AUTHOR A-Z"}
            {selectedSort === "author-name-z-a" && "AUTHOR Z-A"}
            {selectedSort === "title-a-z" && "TITLE A-Z"}
            {selectedSort === "title-z-a" && "TITLE Z-A"}
            {/* <Icon
              name="line-angle-down.svg"
              size="very-small"
              className={`grey ${showSortPicker ? "active" : ""}`}
            /> */}
          </div>
          <CSSTransition
            in={showSortPicker}
            timeout={200}
            classNames="sorting-dropdown-content"
          >
            <ul className="sorting-dropdown-content">
              <li onClick={setSortBy("title-a-z")}>Title A-Z</li>
              <li onClick={setSortBy("title-z-a")}>Title Z-A</li>
              <li onClick={setSortBy("publisher-a-z")}>Publisher A-Z</li>
              <li onClick={setSortBy("publisher-z-a")}>Publisher Z-A</li>
              <li onClick={setSortBy("author-name-a-z")}>Author A-Z</li>
              <li onClick={setSortBy("author-name-z-a")}>Author Z-A</li>
              <li onClick={setSortBy("publication-date-newest")}>
                Publication Date Newest
              </li>
              <li onClick={setSortBy("publication-date-oldest")}>
                Publication Date Oldest
              </li>
            </ul>
          </CSSTransition>
        </div>
      </div>
      <div className="books-grid-wrapper">
        {sortedBooks.map(book => (
          <Book key={book.id} book={book} />
        ))}
      </div>
    </Layout>
  )
}

export default MemberBookGalleryPage
